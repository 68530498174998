/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@include mat.core();

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!


@import 'app/shared/styles/check-theme';
@import 'app/shared/styles/introjs';

/* You can add global styles to this file, and also import other style files */

* {
  box-sizing: initial;
}

html,
body {
  height: calc(100% - constant(safe-area-inset-bottom)) !important;
  height: calc(100% - env(safe-area-inset-bottom)) !important;
}
body {
  margin-top: constant(safe-area-inset-top) !important;
  margin-top: env(safe-area-inset-top) !important;
  margin-bottom: constant(safe-area-inset-bottom) !important;
  margin-bottom: env(safe-area-inset-bottom) !important;
}

.navbar-content {
  padding: 1% 2% 2% 2% !important;
  background-color: white;
}

.larger-form-fields mat-form-field {
  width: 100%;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

.small-pad-right {
  padding-right: 5px;
}

.alert-error {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}

.error-btn {
  color: white;
  background-color: #d9534f;
}

.alert {
  padding: 1%;
  margin-bottom: 10px;
  border: 1px solid transparent;
  border-radius: 4px;
}

.alert-success {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}

.snackbar {
  margin-bottom: calc(env(safe-area-inset-bottom) + 20px) !important;
  margin-bottom: calc(constant(safe-area-inset-bottom) + 20px) !important;
  margin-right: calc(env(safe-area-inset-bottom) + 20px) !important;
  margin-right: calc(constant(safe-area-inset-bottom) + 20px) !important;
  margin-left: calc(env(safe-area-inset-bottom) + 20px) !important;
  margin-left: calc(constant(safe-area-inset-bottom) + 20px) !important;
}

.green-snackbar {
  background: #3c763d !important;
  color: #dff0d8 !important;
  button {
    background: #dff0d8;
    color: #3c763d;
  }
  @extend .snackbar;
}

.red-snackbar {
  background: #d9534f !important;
  color: #dff0d8 !important;
  button {
    background: #dff0d8;
    color: #d9534f !important;
  }
  @extend .snackbar;
}

.warn-snackbar {
  background: #f0ad4e !important;
  color: #dff0d8 !important;
  button {
    background: #dff0d8;
    color: #f0ad4e;
  }
  @extend .snackbar;
}

.success-btn {
  color: white;
  background-color: #4cae4c;
}

.warn-btn {
  color: white;
  background-color: #f0ad4e;
}

.alert-warn {
  color: #985f0d;
  background-color: #fcf8e3;
  background-color: #fcf8e3;
}

.external-padding {
  padding: 0 15px;
  margin: 5px;
  float: left;
}

.mat-card-header .mat-card-title {
  font-size: 15px;
}

.center {
  text-align: center;
}

.border-box {
  box-sizing: border-box;
}

.padding-x {
  padding-left: 5px;
  padding-right: 5px;
}
.w-full {
  width: 100% !important;
}
.h-full {
  height: 100%;
}

// border
.check-box-sizing-border {
  box-sizing: border-box;
}

// place-content
.check-place-content-center-start {
  place-content: center flex-start;
}

// fxFill
.check-fill {
  height: 100%;
  min-height: 100%;
  min-width: 100%;
  width: 100%;
}

// fxFlex
.check-flex-all-space {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0%;
  box-sizing: border-box;
}

// flex-flow
.check-flex-flow { flex-wrap: wrap; flex-direction: row }

// Flex row/col + grid.
.check-flex { display: flex !important; }
.check-flex-row { display: flex !important; flex-direction: row !important; }
.check-flex-col { display: flex !important; flex-direction: column !important; }
.check-grid { display: grid !important; }

// Flex-wrap utils.
.check-flex-wrap { flex-wrap: wrap; }
.check-flex-col-xs { @media screen and (max-width: 599px) { flex-direction: column; } }
.check-flex-col-sm { @media screen and (max-width: 767px) { flex-direction: column; } }
.check-flex-col-md { @media screen and (max-width: 1023px) { flex-direction: column; } }
.check-flex-col-md-imp { @media screen and (max-width: 1023px) { flex-direction: column !important; } }
.check-end-center-md { @media screen and (min-width: 1023px) { justify-content: end !important; align-items: center !important; } }
// Gap.
.check-gap-1 { gap: 1%; }
.check-gap-2 { gap: 2%; }
.check-gap-4 { gap: 4px; }
.check-gap-8 { gap: 8px; }
.check-gap-10 { gap: 10px; }
.check-gap-12 { gap: 12px; }
.check-gap-16 { gap: 16px; }

// Justify content.
.check-space-between { justify-content: space-between; }
.check-space-around { justify-content: space-around; }
.check-space-evenly { justify-content: space-evenly; }
.check-justify-center { justify-content: center; }
.check-justify-start { justify-content: flex-start; }
.check-justify-end { justify-content: flex-end; }

// Align content.
.check-place-content-center-end { align-content: center; justify-content: flex-end !important }
.check-place-content-center-space-around { align-content: center; justify-content: space-around }
.check-place-content-center-start { align-content: center; justify-content: flex-start }
.check-place-content-center-space-between { align-content: center; justify-content: space-between !important }
.check-place-content-center-center { align-content: center; justify-content: center }
.check-place-content-center-flex-start { align-content: center; justify-content: flex-start }
.check-place-content-flex-end-space-around { align-content: flex-end; justify-content: space-around }

.align-end-elements {
  @extend .check-flex-row;
  @extend .check-place-content-center-end;
}

// Align items.
.check-items-center { align-items: center; }
.check-items-start { align-items: start; }
.check-items-end { align-items: end; }
.check-items-flex-end { align-items: flex-end; }

// Align self.
.check-self-center { align-self: center; }
.check-self-start { align-self: start; }
.check-self-end { align-self: end; }
.check-self-stretch { align-self: stretch; }
.check-self-flex-end { align-self: flex-end; }

// Flex/grow/shrink
.check-flex-1 { flex: 1 }  // Same as flex: 1 1 0 (grow, shrink, basis 0). Has similar effect to width: 100%;
.check-flex-grow { flex-grow: 1; }  // Same as flex: 1 1 auto (grow, shrink, basis auto). For spacer, etc.
.check-flex-basis { flex-basis: calc(25% - 16px);}
.check-flex-basis-md { @media screen and (max-width: 950px) { flex-basis: calc(50% - 16px);} }
.check-flex-basis-sm { @media screen and (max-width: 699px) { flex-basis: calc(100% - 16px);} }

.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after,
.mat-input-element:disabled {
  color: rgba(0, 0, 0, 0.7);
}

.white-box-container {
  background-color: #fff;
  margin-bottom: 0;
  padding: 30px;
  padding-top: 20px;
  padding-bottom: 15px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
  border-radius: 7px;
  max-width: 75%;
}

.check-toolbar {
  background: #f5f5f5;
  border-bottom: 1px solid;
  border-top: 1px solid;
  border-top-color: #e7e7e7;
  border-bottom-color: #e7e7e7;
}

.tutorial {
  font-size: large !important;
}

.awesome-menu-icon {
  font-size: large;
}

.warn-menu-icon {
  color: #f44336;
}

.warn-color {
  color: #ff6e40;
}

.green-menu-icon {
  color: #3c763d;
}

.link-menu-icon {
  color: #337ab7 !important;
}

.fill-space {
  width: 100%;
}

.info-box {
  padding: 20px;
  margin: 20px 0;
  border: 1px solid #eee;
  border-left-width: 5px;
  border-radius: 17px;
  border-left-color: #439F39;
}

//table responsive
@media (max-width: 768px) {
  .mat-table[matTableResponsive] {

    .mat-header-row {
      display: flex;
      flex-wrap: wrap;
      height: auto;
      position: sticky;
      top: 0;
      z-index: 2;

      th.mat-header-cell {
        display: flex;
        flex: 1;
        align-items: center;
        padding: 1rem 0.5rem;
        justify-content: space-around;

        & > .mat-sort-header-container {
          padding-left: 15px; // padding is used to center the header table cells, due to the width of the sort icon
        }

        &:not([mat-sort-header], [show-on-mobile]),
        &.mat-sort-header-disabled,
        &.mat-column-actions {
          display: none;
        }
      }
    }

    .mat-row {
      height: auto;

      td.mat-cell:not(.mat-column-actions) {
        display: grid;
        grid-template-columns: 50% 50%;
        padding: 0.5rem;
        text-align: right;
        word-break: break-word;

        & > * {
          grid-column: 2;
        }

        &:before {
          content: attr(data-column-name);
          text-align: left;
        }
      }

      td.mat-column-actions {
        display: flex;
        justify-content: center;
        padding: 0 0.5rem;

        & > button {
          margin: 0.5rem 0;
        }
      }

      &:last-of-type td:last-of-type {
        border: none;
      }

      &:not(:last-of-type) td:last-of-type {
        border-bottom: 0.5rem solid #fafafa;
      }
    }
  }

  .mat-card,
  .mat-expansion-panel {
    .mat-table[matTableResponsive] {
      border: 0.5rem solid #fafafa;
    }
  }
}

.max-width{
  width: 100%;
}

.center-text-vertically {
  align-items: center;
}

.app-scrollable-container {
  height: calc(100% - constant(safe-area-inset-top) - var(--top));
  height: calc(100% - env(safe-area-inset-top) - var(--top));
  overflow-y: auto;
}

.app-container {
  display: inherit;
  max-height: calc(100% - 120px);
}

.hide-field{
  display: none;
}

.actor-employee-export-dialog mat-dialog-container {
  overflow: hidden;
}

// added to fix issue where buttons on the bottom would only partially show on iphone
.check-bottom-content-iphone-fix {
  margin-bottom: constant(safe-area-inset-top) !important;
  margin-bottom: env(safe-area-inset-top) !important;
}

.multiline-tooltip {
  white-space: pre-line;

  & > * {
    text-align: left !important;
  }
}
