@use '@angular/material' as mat;
@include mat.core();

@import "src/app/shared/styles/_variables";

$check-typography-button: mat.define-typography-level(
  $font-family: 'Montserrat',
  $font-weight: 500,
  $font-size: 14px,
  $line-height: 1,
  $letter-spacing: 'normal'
);

$check-typography: mat.define-typography-config(
  $font-family: 'Montserrat',
  $button: $check-typography-button,
  $body-1: mat.define-typography-level(14px, 20px, 500)
);

$check-theme: mat.define-light-theme(
    (
      color: (
        primary: $check-primary,
        accent: $check-accent,
        warn: $check-warn,
      ),
      typography: $check-typography,
      density: -1,
    )
);

@include mat.typography-hierarchy($check-typography);
@include mat.all-component-themes($check-theme);

.rounded {
  border-radius: 17px !important;
}

// menu btns
.mat-mdc-menu-panel {
  @extend .rounded;
}

.mat-mdc-menu-content {
  padding: 0 !important;
}
.mat-mdc-button-base.mat-mdc-button-base.mat-unthemed {
  @extend .rounded;
}
// buttons
.mat-mdc-button {
  @extend .rounded;
}
.mat-mdc-unelevated-button:not(:disabled) {
  @extend .rounded;
  &.mat-primary {
    color: white;
  }
}
.mat-mdc-unelevated-button[disabled], .mat-mdc-unelevated-button.mat-mdc-button-disabled {
  @extend .rounded;
}

.mat-mdc-raised-button:not(:disabled) { // from fulltable
  @extend .rounded;
  color: white !important;
}

// dialogs
.mat-mdc-dialog-container .mdc-dialog__surface {
  @extend .rounded;
}
.mat-mdc-dialog-container .mdc-dialog__content {
  color: black !important;
}
.mat-dialog-container {
  max-height: calc(var(--vh) / 100 * 95) !important;
}

// heading
h2 {
  font-weight: 600 !important;
  font-size: 14px !important;
  font-family: 'Montserrat' !important;
} // solo con typography non avviene override del default

// checkbox
.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-selected-checkmark-color: white;
}

// toolbar
.toolbar-local {
  background-color: white !important;
}
.toolbar-staging {
  background-color: mat.get-color-from-palette($check-primary) !important;
}
.toolbar-dev {
  background-color: mat.get-color-from-palette($check-warn) !important;
}

.login-staging {
  color: mat.get-color-from-palette($check-primary);
}
.login-dev {
  color: mat.get-color-from-palette($check-warn) !important;
}

hr:not(.default) {
  border-radius: 50px;
  height: 9px !important;
  background-color: mat.get-color-from-palette($check-primary, default);
  max-width: 128px !important;
}

a {
  color: mat.get-color-from-palette($check-primary, 800) !important;
  text-decoration: underline !important;
}
.panel a {
  color: mat.get-color-from-palette($check-accent, 800) !important;
  text-decoration: underline !important;
}

.green-check {
  background-color: mat.get-color-from-palette($check-primary, default) !important;
}

.link-menu-icon {
  color: mat.get-color-from-palette($check-primary, default) !important;
}

.mat-table {
  width: 100%;
  overflow: auto;
  border-radius: 20px;
}


.check-basic-colors {
  background-color: mat.get-color-from-palette($check-primary, default) !important;
  color: white !important;
}
// mat chips
.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) .mdc-evolution-chip__text-label {
  color: white !important;
}

.panel {
  border-radius: 20px !important;
}

.mat-card {
  box-shadow: none;
  background-color: transparent;
}

.mapboxgl-map {
  border-radius: 20px;
}

.mat-toolbar:not(.toolbar) {
  border-radius: 20px;
  box-shadow: none !important;
  //margin: 16px 16px 0 16px;
  width: calc(100% - 32px);
}

.filter-toolbar {
  margin-bottom: 10px;
  background: transparent !important;
  border-radius: 0 !important;
}

.secondary-toolbar {
  width: calc(100% - 4%) !important;
  margin: 1% 2% 0 2% !important;
  background-color: transparent;
  padding-left: 0 !important;
  font-size: x-large !important;
}

.mat-card-header-text {
  margin: 0 !important;
}

mat-card {
  border-radius: 17px !important;
  border: 1px solid #cfcfcf;
  box-shadow: none !important;
  padding: 0 !important;
}
.mat-flat-button {
  box-shadow: none !important;
  border: 1px solid #e3e3e3;
}

mat-progress-bar {
  height: 8px !important;
  border-radius: 4px !important;
}

.mat-flat-button {
  border-radius: 999rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e3e3e3 !important;
}
.mat-button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mat-menu-panel {
  overflow: hidden !important;
  border-radius: 17px !important;
  box-shadow: none;
  border: 1px solid #e3e3e3;
  min-height: 0 !important;
}

.mat-menu-content {
  padding: 0 !important;
  .mat-menu-item {
    font-size: 14px;
    font-weight: 500;
    color: black;
    width: 100% !important;
    .mat-icon {
      color: black;
      font-size: 16px;
      width: 16px;
      height: 16px;
    }

    &:last-child {
      border: none !important;
    }
  }
  .mat-menu-item:last-child {
    border: none !important;
  }
}

.mat-dialog-container {
  border-radius: 17px !important;
  box-shadow: none;
  border: 1px solid #e3e3e3;
}

.mat-form-field-outline {
  background-color: #f6f7f9;
}

.mat-form-field-label-wrapper {
  .mat-form-field-label {
    color: #4f4f4f !important;
    font-weight: 400 !important;
  }
}

.mapboxgl-canvas {
  width: 100% !important;
}

.mat-datepicker-content {
  border-radius: 17px !important;
  box-shadow: none;
  border: 1px solid #e3e3e3;
}

.mat-select,
.mat-input-element {
  font-weight: 500 !important;
}

.number-picker {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
  &-header {
    color: rgba(0, 0, 0, 0.6);
    font-size: 12px;
  }
  &-header,
  &-content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    .mat-form-field {
      margin: 0 20px -1.34375em 20px;
      max-width: calc((100% - 72px) - 40px);
      width: 200px;
      input {
        text-align: center;
      }
    }
    button {
      font-size: 36px;
      border-radius: 999rem !important;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 36px;
      min-height: 36px;
      padding: 0;
      height: 36px;
      width: 36px;
      &.minus-button {
        padding-bottom: 6px;
      }
    }
  }
}

.fullsize-mat-tabs {
  .mat-tab-body-wrapper {
    height: 100% !important;
  }
  .mat-tab-label {
    opacity: 1;
    font-weight: 600;
  }
}


//mermaid
.mermaid {
  rect.label-container {
    fill: mat.get-color-from-palette($check-primary, default) !important;
  }
  g.label {
    color: white !important;
  }
}

// snackbar
.mat-mdc-snack-bar-container {
  &.green-snackbar {
    --mdc-snackbar-container-color: mat.get-color-from-palette($check-primary, default);
    --mat-mdc-snack-bar-button-color: #000;
    --mdc-snackbar-supporting-text-color: #fff;
  }
}
.mat-mdc-snack-bar-container {
  &.red-snackbar {
    --mdc-snackbar-container-color: $check-red;
    --mat-mdc-snack-bar-button-color: #000;
    --mdc-snackbar-supporting-text-color: #fff;
  }
}

.full-page-dialog {
  height: calc(100% - 64px);
  margin-top: 64px;
  width: 100%;
  @media screen and (min-width: 1000px) {
    max-height: 80vh !important;
    overflow: auto;
    margin-top: 0;
  }
  @media screen and (max-width: 1000px) {
    max-width: 100% !important;
    .mat-mdc-dialog-container .mdc-dialog__surface {
      border-radius: 17px 17px 0 0 !important;
    }
  }

  .mat-mdc-dialog-content {
    max-height: calc(100vh - ($toolbar-height + $dialog-header-height)) !important;
  }

  .mat-dialog-container {
    border-radius: 0 !important;
    @media screen and (min-width: 1000px) {
      border-radius: 17px !important;
    }
    @media screen and (max-width: 1000px) {
      padding: 0 !important;
    }
  }

  .mat-mdc-dialog-container .mdc-dialog__surface {
    @media screen and (min-width: 1000px) {
      border-radius: 17px !important;
    }
  }
}

.preset-list-dialog .mat-dialog-container {
  overflow: hidden;
}

.preset-list-dialog .mat-dialog-content {
  max-height: fit-content !important;
}

.responsive-dialog {
  mat-dialog-container {
    @media screen and (max-width: 500px) {
      padding: 10px !important;
    }
  }
}
.cursor-initial {
  cursor: initial;
}


@media screen and (min-width: 1000px) {
  .fit-content-dialog {
    height: fit-content;
  }
}

