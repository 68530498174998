.nested-tutorial-link {
    background: initial;
    border: none;
    color: #4caf50;
    text-decoration: underline;
    cursor: pointer;
    font-weight: 600;
    padding: 0;
}

.mat-tutorial-button {
    text-decoration: none !important;
    border-radius: 50px;
    height: 36px;
    min-width: 64px;
    padding: 0 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &.introjs-prevbutton {
        background-color: #e3e3e3;
        color: initial !important;
    }

    &.introjs-nextbutton {
        background-color: #4caf50;
        color: white !important;
    }

    &.introjs-disabled {
        display: none;
    }
}

.introjs-skipbutton {
    display: none !important;
}

.introjs-tooltip {
    padding: 5px;
}

.introjs-tooltip-header {
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.introjs-tooltip-title {
    font-size: 18px !important;
    font-weight: 600 !important;
    margin: 0 !important;
}

.introjs-tooltiptext {
    padding: 10px !important;
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    margin-bottom: 10px;
}

div.introjs-dontShowAgain {
    padding: 0 10px !important;
    display: flex;
    align-items: center;

    input {
        width: 14px !important;
        height: 14px !important;
        accent-color: #49a84d !important;
    }
}

.introjs-progressbar {
    background-color: #4caf50 !important;
}

.introjs-tooltip-title,
.introjs-tooltiptext,
.introjs-tooltiptext a,
.introjs-dontShowAgain label,
.mat-tutorial-button,
.nested-tutorial-link {
    font-family: Montserrat !important;
}